import { EventAggregator } from "aurelia-event-aggregator";
import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";
import routerHelper from "helpers/routerHelper";
import { AdditionalFieldType } from "api/enums/additional-field-type";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import { AdditionalFieldMapper } from "components/pages/additional-fields/helpers/additional-field-mapper";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { CloneHelper } from "helpers/cloneHelper";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceQuotationSecuritySettingsModel } from "api/models/company/security/service-quotation-security-settings-model";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";

@autoinject
export class AdditionalFieldsService {

    public actions: any = {};
    public serviceType: string = "";
    public quotationId: number = 0;
    public additionalFieldsSource: number = AdditionalFieldsSourceType.ServiceQuotation;
    public saveAdditionalFieldsButton: any = true;
    public currentFields: any = null;
    public unModifiedFields: any = null;
    public quotation!: ServiceCallQuotationDetailsModel;
    public unmodifiedQuotation: ServiceCallQuotationDetailsModel | null = null;
    public securitySettings: ServiceQuotationSecuritySettingsModel | null = null;

    @computedFrom("quotation", "quotation.Status", "securitySettings")
    public get readonly(): boolean {
        if (!this.quotation) {
            return true;
        }

        return this.serviceCallQuotationSecurityService.isQuotationReadOnlyInMobile(this.quotation, this.securitySettings) || this.quotation!.IsServiceContractNotFound;
    }

    constructor(private readonly i18n: I18N, private readonly eventAggregator: EventAggregator, private readonly notificationHelper: NotificationHelper, private readonly router: Router, private readonly additionalFieldMapper: AdditionalFieldMapper, private readonly serviceCallQuotationService: ServiceCallQuotationService, private readonly serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService, private readonly additionalFieldsHelper: AdditionalFieldsHelper) {
    }

    public async activate(params: any): Promise<void> {
        this.quotationId = params.quotationId;
        await this.initQuotation(this.quotationId);
        await this.initSecuritySettings();
    }

    public isDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        const stringifyUnmodifiedQuotation = JSON.stringify(this.unmodifiedQuotation).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrentQuotation = JSON.stringify(this.quotation).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodifiedQuotation !== stringifyCurrentQuotation;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }

        return true;
    }

    public async save(): Promise<any> {
        await this.serviceCallQuotationService.saveQuotation(this.quotation, true, true);
        await this.initQuotation(this.quotationId);
        if (!await this.checkAllRequiredAdditionalFieldsAreEntered()) {
            const warning = this.additionalFieldsHelper.getRequiredRessourceBySourceType(this.additionalFieldsSource);
            this.notificationHelper.showWarning(warning, "", { timeOut: 0 });
        }
        routerHelper.navigateBack();
    }

    public async checkAllRequiredAdditionalFieldsAreEntered(): Promise<boolean> {
        if (!this.quotation) { return true; }

        return await this.serviceCallQuotationService.checkIfRequiredAdditionalFieldsAreEntered(this.quotation, true);
    }

    private async initQuotation(quotationId: number): Promise<void> {
        this.quotation = await this.serviceCallQuotationService.getQuotation(quotationId, true, true, false, false, false);
        this.unmodifiedQuotation = CloneHelper.deepClone(this.quotation);
    }

    private async initSecuritySettings(): Promise<void> {
        this.securitySettings = await this.serviceCallQuotationSecurityService.getSecuritySettings();
    }
}
