import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import { PurchaseOrderService } from "services/purchase-order-service";
import { I18N } from "aurelia-i18n";
import { bindable } from "aurelia-typed-observable-plugin";

import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";
import { CloneHelper } from "helpers/cloneHelper";

@autoinject
export class MaterialOrderMemo {

    public readonly: boolean = false;
    public dispatchId: number = 0;
    public orderId: any = null;
    public extension: any = null;
    public memo: string | null = "";
    public element: HTMLTextAreaElement | null = null;
    public unModified: any = null;
    @bindable public purchaseOrder: any = null;

    private minPaddingNoScroll: number = 2;

    constructor(private readonly i18n: I18N, private readonly purchaseOrderService: PurchaseOrderService, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {}

    public async activate(params: any): Promise<void> {
        this.purchaseOrder = await this.purchaseOrderService.GetPurchaseOrder(params.orderId, params.extension);
        this.unModified = CloneHelper.deepClone(this.purchaseOrder);
        this.dispatchId = params.dispatchId;
    }

    public isDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.unModified) {
            return false;
        }

        if (!this.purchaseOrder) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.purchaseOrder).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty()) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public attached(): void {
        this.resize();
    }

    public close(): void {
        routerHelper.navigateBack();
    }

    public async save(): Promise<void> {
        await this.purchaseOrderService.SavePurchaseOrderHeaderForDispatch(this.dispatchId, this.purchaseOrder).then(async (success: any) => {
            if (success) {
                this.unModified = null;
                routerHelper.navigateBack();
            }
        });
    }

    private resize(): void {
        if (this.element) {
            this.element.style.width = "100%";
            this.element.style.height = this.element.scrollHeight + this.minPaddingNoScroll + "px";
        }
    }
}
