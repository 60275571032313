import _ from "underscore";
import { I18N } from "aurelia-i18n";
import { Redirect } from "aurelia-router";
import { autoinject } from "aurelia-framework";

import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import routerHelper from "helpers/routerHelper";
import settingHelper from "helpers/settingHelper";
import attendanceHelper from "helpers/attendanceHelper";
import queryStringHelper from "helpers/queryStringHelper";
import notificationHelper from "helpers/notificationHelper";

import { CreationSource } from "api/enums/creation-source";

import templateService from "services/templateService";
import dispatchService from "services/dispatchService";
import attendanceService from "services/attendanceService";
import dailyEntryService from "services/dailyEntryService";

import "pages/projects/dailyEntry/attendance_template.html";
import "pages/projects/dailyEntry/attendance_templateEquipment.html";
import RouteRepository from "repositories/routeRepository";

@autoinject
export class ProjectAttendance {
    public dateHelper: typeof dateHelper = dateHelper;
    public routerHelper: typeof routerHelper = routerHelper;

    public dispatchDate: any;
    public dispatchModel: any = "";
    public dispatchProjectCode: string = "";
    public isResponsible: boolean = false;
    public readonly: boolean = false;
    public isInFuture: boolean = false;
    public teams: any[] = [];
    public employees: any[] = [];
    public equipments: any;
    public selectedItem: any;
    public totalAttendanceCount: any = 0;
    public employeesTotalAttendanceCount: number = 0;
    public employeesPresentAttendanceCount: number = 0;
    public presentAttendanceCount: number = 0;
    public selectedFilter: string = "";
    public permitted: boolean = false;
    public CreatedByMaestro: number = CreationSource.Maestro;
    public canDeleteDailyEntry: boolean = false;
    public isProjectResponsible: boolean = false;
    public isTeamLeader: boolean = false;

    public loadAvailableAttendances: any = {
        transport: async (params: any, success: any, failure: any): Promise<any> => {
            this.selectedFilter = params.data.filterSelected;
            await attendanceService.listAvailableAttendanceForProject(this.dispatchProjectCode,
                this.dispatchDate,
                this.dispatchModel,
                this.getSelectedFilterToApi(params.data.filterSelected),
                    params.data.filterChecked,
                    params.data.filter,
                    params.data.page || 1)
                .then(success, failure);

        },
        mapResults: (item: any): any => {
            const attendanceTypes = enumHelper.attendanceTypes();
            switch (Number.parseInt(this.selectedFilter)) {
                case attendanceTypes.EMPLOYEE.id:
                return {
                    id: "emp-" + item.EmployeeId,
                    text: item.EmployeeId + " - " + item.FirstName + " " + item.LastName,
                    type: this.selectedFilter,
                    data: item
                };
                case attendanceTypes.TEAM.id:
                return {
                    id: "team-" + item.Id,
                    text: item.Id + " - " + item.Name,
                    data: item,
                    type: this.selectedFilter
                };
                case attendanceTypes.EQUIPMENT.id:
                return {
                    id: "equip-" + item.EquipmentId,
                    text: item.EquipmentId + " - " + item.EquipmentDescription,
                    data: item,
                    type: this.selectedFilter
                };
            }
        }
    };

    constructor(private readonly i18n: I18N, private routeRepository: RouteRepository) {
    }

    public canActivate(): any {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        return true;
    }

    public activate(params: any): any {
        this.bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);
        this.loadData();
    }

    public bindViewModel(dispatchProjectCode: string, dailyEntryDate: any, q: any): any {
        this.dispatchModel = settingHelper.getSelectedDispatchModel();
        this.dispatchProjectCode = dispatchProjectCode;
        this.dispatchDate = dailyEntryDate;
        this.isInFuture = !dateHelper.isTodayOrBefore(dailyEntryDate.replace("-", "/"));
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(q);
        this.isTeamLeader = queryStringHelper.parseIsTeamLeader(q);
        this.readonly = queryStringHelper.parseReadonly(q);
        this.permitted = this.attendancePermission();
        this.canDeleteDailyEntry = queryStringHelper.parseCanDeleteDailyEntry(q);
    }

    public bindLoadedData(loadedData: any): any {
        this.CreatedByMaestro = CreationSource.Maestro;
        this.totalAttendanceCount = loadedData.length;
        const presentAttendanceCount = _.filter(loadedData, (item: any) => {
            return item.Presence >= enumHelper.attendance().ONSITE;
        }).length;

        loadedData = _.map(loadedData, (item: any) => {
            item.attendanceStatus = item.Presence;
            item.isInactive = item.IsInactive;
            return item;
        });

        const groupAndSortAttendances = attendanceHelper.groupAndSortAttendances(loadedData, true);
        const employeesLoadedData = attendanceHelper.getEmployeesFromGroupedAndSortedAttendances(groupAndSortAttendances);

        this.teams = attendanceHelper.getTeamsFromGroupedAndSortedAttendances(groupAndSortAttendances);
        this.employees = employeesLoadedData;
        this.equipments = attendanceHelper.getEquipmentFromAttendances(loadedData);
        this.presentAttendanceCount = presentAttendanceCount;
    }

    public async loadData(): Promise<any> {
        return await attendanceService.listForDate(this.dispatchProjectCode, this.dispatchDate, this.getAllowEquipmentTimeEntryForSimpleUser())
            .then((data: any) => {
                this.bindLoadedData(data);
            });
    }

    public getAllowEquipmentTimeEntryForSimpleUser(): any {
        return templateService.getCurrentTemplateConfigs().AllowEquipmentTimeEntry;
    }

    public genUrl(rel: string, additionalParameter?: any, additionalParameter2?: any): string {
        let url = "";
        if (additionalParameter) {
            if (additionalParameter2) {
                url = routerHelper.navigateTo(rel, this.dispatchProjectCode, this.dispatchDate, additionalParameter, additionalParameter2);
            } else {
                url = routerHelper.navigateTo(rel, this.dispatchProjectCode, this.dispatchDate, additionalParameter);
            }
        } else {
            url = routerHelper.navigateTo(rel, this.dispatchProjectCode, this.dispatchDate);
        }

        return url + routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly, isTeamLeader: this.isTeamLeader });
    }

    public genEmployeeUrl(route: string, employeeId: number, dispatchId: number): string {
        return this.genUrl(route, employeeId, dispatchId);
    }

    public genTimeEntryUrl(route: string): string {
        return this.genUrl(route);
    }

    public async updateAttendanceStatus(item: any, model: any, validateActivity: any): Promise<any> {
        if (validateActivity && item.HasTimeEntries) {
            if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmation"))) {
                await this.updateAttendanceStatusInDatabase(item, model);
            }
        } else {
            await this.updateAttendanceStatusInDatabase(item, model);
        }
    }

    public async updateAttendanceStatusInDatabase(item: any, model: any): Promise<void> {
        await dispatchService.setAttendanceStatus(item.DispatchId, model).then((result: any) => this.bindLoadedData(result));
        item.attendanceStatus = model.Presence;
    }

    public async saveInactive(dispatchId: string | number, inactive: any): Promise<any> {
        await dispatchService.setInactive(dispatchId, inactive)
            .then((data: any) => {
                //need to retreive inactive item in case of linked equipment that has been unlinked in the process...
                const eq = _.find(this.equipments.Attendances, (equip: any) => {
                    return equip.DispatchId === dispatchId;
                });
                eq.isInactive = inactive;
            });
    }

    public async setInactive(dispatchId: number, item: any): Promise<void> {
        if (item.HasTimeEntries && notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmationEquipment"))) {
            await this.saveInactive(dispatchId, !item.isInactive);
        } else {
            this.saveInactive(dispatchId, !item.isInactive);
        }
    }

    public getSelectedFilterToApi(id: number): string {
        const attendanceTypes = enumHelper.attendanceTypes;
        if (id === attendanceTypes().EMPLOYEE.id) {
            return "employees";
        }
        if (id === attendanceTypes().TEAM.id) {
            return "teams";
        }
        if (id === attendanceTypes().EQUIPMENT.id) {
            return "equipments";
        }
        return "";
    }

    public attendancePermission(): boolean {
        //Backend are setting readonly to True if D.E is closed or in future
        //We don't permit only if the D.E is in the past and is closed
        return this.isInFuture || !this.readonly;
    }

    public async addAttendances(list: any): Promise<void> {
        const attendanceTypes = enumHelper.attendanceTypes;

        const dataToPost = _.map(list, (item: any) => {
            return {
                EmployeeId: item.type === attendanceTypes().EMPLOYEE.id ? item.id.replace("emp-", "") : null,
                EquipmentId: item.type === attendanceTypes().EQUIPMENT.id ? item.id.replace("equip-", "") : null,
                TeamId: item.type === attendanceTypes().TEAM.id ? item.id.replace("team-", "") : null
            };
        });

        await attendanceService.addAttendanceForProject(this.dispatchProjectCode, this.dispatchDate, dataToPost, this.dispatchModel, true)
            .then((data: any) => {
                if (data.SaveResponse === enumHelper.attendanceAddReturnType().NONE) {
                    notificationHelper.showWarning(this.i18n.tr("msg_AttendanceAdd_TeamNotAvailableAnymore"));
                } else if (data.SaveResponse === enumHelper.attendanceAddReturnType().PARTIAL) {
                    notificationHelper.showWarning(this.i18n.tr("msg_AttendanceAdd_SomeAllTeamMembersNotAvailableAnymore"));
                }

                this.bindLoadedData(data.List);
            });
    }

    public async removeAttendance(item: any): Promise<void> {
        let isLastAttendee = false;
        let confirmDeleteAttendee = false;

        const containsTimeEntriesOrBonus = await dispatchService.dipatchProjectHasTimeEntriesAndBonus(item.DispatchId);
        if (containsTimeEntriesOrBonus) {
            notificationHelper.showWarning(this.i18n.tr("WarningProjectDispatchHasTimeEntriesAndBonus"), "", { timeOut: 0 });
            return;
        }

        isLastAttendee = await dispatchService.dipatchProjectisLastAttendee(item.DispatchId);

        if (isLastAttendee) {
            if (!this.canDeleteDailyEntry) {
                notificationHelper.showWarning(this.i18n.tr("msg_CannotDeleteLastAttendeeDailyEntryCannotBeDeleted"), "", { timeOut: 0 });
                return;
            }
            confirmDeleteAttendee = await notificationHelper.showConfirmation(this.i18n.tr("msg_ProjectDispatchDeleteLastAttendee"));
        } else {
            confirmDeleteAttendee = await notificationHelper.showConfirmation(this.i18n.tr("msg_DeleteAttendanceConfirmation"));
        }

        if (confirmDeleteAttendee) {
            if (isLastAttendee) {
                this.deleteEntry();
            } else {
                const presences = await dispatchService.delProjectDispatch(item.DispatchId);
                if (presences && presences.length > 0) {
                    this.bindLoadedData(presences);
                } else {
                    let url = routerHelper.navigateTo("Project_Detail", this.dispatchProjectCode);
                    url += routerHelper.addQuerystring({ isProjectResponsible: this.isProjectResponsible, readonly: this.readonly, isTeamLeader: this.isTeamLeader });

                    routerHelper.navigate(url);
                }
            }
        }
    }

    public editHoursAttendance(item: any): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Project_Detail_Daily_Attendance_Hours_Edit.name, {
            dispatchProjectCode: this.dispatchProjectCode,
            dailyEntryDate: this.dispatchDate,
            dispatchId: item.DispatchId,
            dateFrom: item.DispatchStartTime,
            dateTo: item.DispatchEndTime,
            dispatchTemplateId: item.DispatchTemplateId,
            equipmentDescription: item.EquipmentCode + " - " + item.EquipmentDescription,
            q: routerHelper.buildQueryString({
                isProjectResponsible: this.isProjectResponsible,
                readonly: this.readonly,
                isTeamLeader: this.isTeamLeader
            })
        });
    }

    public async unbindEquipment(item: any): Promise<any> {
        if (await notificationHelper.showConfirmation(this.i18n.tr("msg_UnbindEquipmentConfirmationText"))) {
            await dispatchService.breakEquipmentLink(item.DispatchId).then((data: any) => {
                this.bindLoadedData(data);

            });
        }
    }

    public async updateAttendanceStatus_Absent(item: any): Promise<any> {
        const newStatus = item.attendanceStatus === enumHelper.attendance().ABSENT ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ABSENT;

        if (newStatus === enumHelper.attendance().ABSENT) {
            if (item.HasTimeEntries) {
                if (await notificationHelper.showConfirmation(this.i18n.tr("msg_AbsentConfirmation"))) {
                    routerHelper.navigate(routerHelper.getRelativeUrl("absence", item.DispatchId));
                }
            } else {
                routerHelper.navigate(routerHelper.getRelativeUrl("absence", item.DispatchId));
            }
        } else {
            this.updateAttendanceStatus(item, { Presence: newStatus, AllowEquipmentTimeEntryForSimpleUser: this.getAllowEquipmentTimeEntryForSimpleUser() }, true);
        }
    }

    public async updateAttendanceStatus_Present(item: any): Promise<any> {
        const newStatus = item.attendanceStatus >= enumHelper.attendance().ONSITE ? enumHelper.attendance().UNDEFINED : enumHelper.attendance().ONSITE;
        await this.updateAttendanceStatus(item, { Presence: newStatus, AllowEquipmentTimeEntryForSimpleUser: this.getAllowEquipmentTimeEntryForSimpleUser() }, newStatus === enumHelper.attendance().UNDEFINED);
    }

    public async updateAttendanceStatus_Present_All(): Promise<any> {
        if (this.presentAttendanceCount === this.totalAttendanceCount) {
            return;
        }

        await attendanceService.updateStatusForAllDispatches(this.dispatchProjectCode, this.dispatchDate, this.getAllowEquipmentTimeEntryForSimpleUser())
            .then((data: any) => this.bindLoadedData(data));
    }

    public navigateToTimeEntry(): void {
        const url = this.genTimeEntryUrl("Project_Detail_Daily_Entry_TimeEntry");
        routerHelper.navigate(url, { replace: true, trigger: true });
    }

    public async inactiveClicked(item: any): Promise<void> {
        if (item.LinkedDispatchId) {
            await this.unbindEquipment(item).then(async () => {
                await this.setInactive(item.LinkedEquipment.DispatchId, item);
            });
        } else {
            this.setInactive(item.DispatchId, item);
        }
    }

    public async deleteEntry(): Promise<void> {
        await dailyEntryService.deleteDailyEntry(this.dispatchProjectCode, this.dispatchDate);
        window.history.go(-2);
    }

    public cancelAllDispatches(): void {
        if (!this.canDeleteDailyEntry) {
            notificationHelper.showWarning(this.i18n.tr("msg_DailyEntryHasStarted"), "", { timeOut: 0 });
            return;
        }

        routerHelper.navigate(this.genUrl("Project_Daily_Entry_Cancel_Attendances"));
    }

}
