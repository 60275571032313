import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import routerHelper from "helpers/routerHelper";

@autoinject()
export class FieldServiceMaterialRequisitionEdit {

    public actions: any = {};
    public workOrderId: string = "";
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;

    public activate(params: any): any {
        this.workOrderId = params.workOrderId;
        this.requisitionId = params.requisitionId;

        const paramsQs = routerHelper.getQuerystring(params.q);
        this.isProjectResponsible = (paramsQs && paramsQs.isProjectResponsible === "true") || false;
        if (paramsQs && paramsQs.readonly) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForFieldService.bind(materialRequisitionService, this.workOrderId),
            setSpecificFields: this.setSpecificFields.bind(this)
        };
    }

     public setSpecificFields(item: any): any {
        item.workOrderId = this.workOrderId;
        return item;
    }
}
