import { autoinject } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import _ from "underscore";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import services from "services/serviceService";
import routerHelper from "helpers/routerHelper";
import labelHelper from "helpers/labelHelper";
import settingHelper from "helpers/settingHelper";
import settings from "repositories/settingRepository";
import notificationHelper from "helpers/notificationHelper";
import pdfTransactionStatusHelper from "helpers/pdfTransactionStatusHelper";
import RouteRepository from "repositories/routeRepository";
import UserAccessService from "services/user-access-service";
import { WorkOrderMaintenanceType } from "api/enums/work-order-maintenance-type";
import { EmergencyTypes } from "enums/emergency-types";
import { CallType } from "api/enums/call-type";
import { UnitSystem } from "api/enums/unit-system";
import UserSettingService from "services/userSettingsService";
import { GeolocationService } from "services/geolocationService";
import { StringHelper } from "helpers/string-helper";
import { ServiceCallEmergencyTypeService } from "services/service-call-emergency-type-service";
import { ServiceCallEmergencyTypeModel } from "api/models/company/service-call/service-call-emergency-type-model";
import defaultService from "services/defaultService";

@autoinject()
export class ServiceIndex {

    public SECTION_TABS: any = {
        ONGOING: 1,
        UNPLANNED: 2,
        COMPLETED: 3
    };

    public dateHelper: typeof dateHelper = dateHelper;
    public enumPresence: enumHelper.servicePresence = enumHelper.servicePresence();
    public enumPdfStatus: enumHelper.servicePdfStatus = enumHelper.servicePdfStatus();
    public labelHelper: typeof labelHelper = labelHelper;
    public routerHelper: typeof routerHelper = routerHelper;

    public sectionTabs: any = this.SECTION_TABS;
    public showAddEntryButton: boolean = false;
    public showAddWOButton: boolean = false;
    public addEntry: string = "";
    public addWO: string = "";

    public currentList: any[] = [];
    public historyList: any[] = [];
    public unplannedList: any[] = [];

    public lblAssignmentStatus: string = "";

    public completedFinishedLoading: boolean = false;
    public list: any[] = [];
    public page: number = 1;
    public sectionShown: number = this.SECTION_TABS.ONGOING;
    public servicePresenceTypes: any[] = [];
    public serviceStatus: any[] = [];
    public isScrollMessageShown: boolean = false;
    public scrollMessageText: string = this.i18n.tr("ScrollForMore");
    public workOrderMaintenanceType: typeof WorkOrderMaintenanceType = WorkOrderMaintenanceType;
    public emergencyTypeEnum: typeof EmergencyTypes = EmergencyTypes;
    public callType: typeof CallType = CallType;

    public selectedWithinRange: any = {};
    public emergencyTypeAll: any = { id: "-1", text: this.i18n.tr("AllEmergencyTypes")};

    public selectedEmergencyType: any = null;
    public userSelectedWithinRangeId: number = -1;
    public UnitSystemToDisplay: string = UnitSystem.Metric;
    public unitSystemMetric: string = UnitSystem.Metric;
    public unitSystemImperial: string = UnitSystem.Imperial;
    public position: any = null;
    public enumHelper: typeof enumHelper;
    public emergencyTypes: any[] = [];
    public withinRanges: any[] = [];
    public parseFloat: any = null;

    constructor(private readonly i18n: I18N, private readonly routeRepository: RouteRepository, private readonly userAccessService: UserAccessService, private readonly  geolocationService: GeolocationService, private readonly  serviceCallEmergencyTypeService: ServiceCallEmergencyTypeService) {

        this.serviceCallEmergencyTypeService = serviceCallEmergencyTypeService;
        this.enumHelper = enumHelper;
        this.i18n = i18n;
        this.parseFloat = parseFloat;
    }

    public async activate(): Promise<void> {
        const sectionShown = defaultService.getServiceLastSectionShown();
        if (sectionShown) {
            this.sectionShown = +sectionShown;
        }
        this.bindViewModel();
        await this.loadWithinRanges();
        await this.loadEmergencyTypes();
        await this.load();
    }

    public deactivate(): void {
        this.disposeScroll();
    }

    public bindViewModel(): void {
        this.servicePresenceTypes = _.values(enumHelper.servicePresence());
        this.serviceStatus = _.values(enumHelper.serviceStatus());
    }

    public load(): any {
        if (!settings.getCompany()) {
            return;
        } else if (this.sectionShown === this.SECTION_TABS.COMPLETED) {
            return this.loadPreviousData();
        } else if (this.sectionShown !== this.SECTION_TABS.UNPLANNED) {
            return this.loadData();
        }
    }

    public async loadPreviousData(): Promise<any> {
        this.page = 1;
        return await this.getPreviousData(this.page).then((list: any) => {
            list = this.getPreviousDataDone(list);
            this.historyList = list;
            this.list = list;
        });
    }

    public loadUnplannedData(): any {
        this.page = 1;
        return this.getUnplannedData(this.page).then((list: any) => {
            list = this.getUnplannedDataDone(list);
            this.unplannedList = list;
            this.list = list;
        });
    }

    public getUnplannedDataDone(data: any): any[] {
        if (data.morePages) {
            this.initScroll();
        } else {
            this.completedFinishedLoading = true;
            this.disposeScroll();
        }

        this.initializeCallList(data);

        return this.initializeUnplannedCallsList(data);
    }

    public async getPreviousData(page: any): Promise<any> {
        routerHelper.showLoading();

        return await services.getPrevious(page)
            .then((result: any) => {
                routerHelper.hideLoading();
                return result;
            }, () => {
                routerHelper.hideLoading();
                this.disposeAll();
            });
    }

    public async getLocation(): Promise<void> {
        try {
            this.position = await this.geolocationService.getCurrentLocation();
        } catch (error) {
            this.position = null;
            routerHelper.hideLoading();
            if (StringHelper.startsWith(error.message, "Only secure origins are allowed") || error.message.indexOf("Access to geolocation was blocked over insecure connection") > -1 || error.message.indexOf("User denied Geolocation") > -1) {
                notificationHelper.showWarning(this.i18n.tr("LocationRequireSSL"), undefined, { timeOut: 0 });
            } else {
                notificationHelper.showWarning(error.message, undefined, { timeOut: 0 });
            }
        }
    }

    public async getUnplannedData(page: number): Promise<any> {
        await this.getLocation();
        routerHelper.showLoading();
        let emergencyId = null;
        if (this.selectedEmergencyType && this.selectedEmergencyType.id) {
            emergencyId = this.selectedEmergencyType.id === this.emergencyTypeAll.id ? null : this.selectedEmergencyType.id;
        }
        return services.getUnplanned(page, this.selectedWithinRange ? this.selectedWithinRange.id : -1, this.getUnitSystem(), this.position ? this.position.coords.latitude : 0, this.position ? this.position.coords.longitude : 0, emergencyId)
            .then((result: any) => {
                routerHelper.hideLoading();
                return result;
            }, (err: any) => {
                this.disposeAll();
                routerHelper.hideLoading();
            });
    }

    public getPreviousDataDone(data: any): any[] {
        if (data.morePages) {
            this.initScroll();
        } else {
            this.completedFinishedLoading = true;
            this.disposeScroll();
        }

        return this.initializeCallList(data);
    }

    public loadData(fromShow: boolean =  false): any {
        routerHelper.showLoading();
        return services.getCurrent(settings.getDispatchTemplate())
            .then((result: any) => {
                this.initialise(result, fromShow);
            }, () => {
                this.disposeAll();
            }).then(() => routerHelper.hideLoading());

    }

    public initialise(data: any, fromShow: boolean = false): void {
        const ls = this.initializeCallList(data);

        this.currentList = ls;
        if (!fromShow) {
            this.show(this.sectionShown);
        } else {
            this.list = this.currentList;
            this.disposeScroll();
        }
    }

    public initAddButtons(data: any): void {
        this.addEntry = data.addEntry;
        this.showAddEntryButton = data.addEntry !== enumHelper.serviceCallAddEntryStatus().NO;

        this.addWO = data.addWO;
        this.showAddWOButton = data.addWO !== enumHelper.serviceCallAddEntryStatus().NO;
    }

    public initializeCallList(data: any): any[] {

        this.initAddButtons(data);

        const ls: any[] = [];

        _.each(data.Calls, (call: any) => {
            this.appendPropertiesToCall(call);

            if (call.AssignedDate) {
                if (!_.findWhere(ls, { date: dateHelper.dateFromUTC(call.AssignedDate, "ddd, ll") })) {
                    ls.push({
                        date: dateHelper.dateFromUTC(call.AssignedDate, "ddd, ll"),
                        helperName: call.HelperName,
                        assignedEquipmentDescription: call.AssignedEquipmentDescription,
                        ls: [],
                        isOpened: true
                    });
                }
            }
        });

        this.pushCallsByDate(ls, data);

        return ls;
    }

    public appendPropertiesToCall(call: any): void {
        call.lblAssignmentStatus = this.initializeAssignmentStatus(call);
        this.initializePdfStatus(call);
    }

    public initializeAssignmentStatus(call: any): string {
        let temp = (call.ServiceType === "S" ? this.labelHelper.getCallTypeLabel(call.CallType, settings.getLanguage().toLowerCase()) : this.i18n.tr("WorkOrder")) + " " + call.Id;
        if (call.Presence !== this.enumPresence.COMPLETED.id) {
            temp += " - " + this.getPresence(call.Presence).label;
        }

        return temp;
    }

    public initializePdfStatus(call: any): void {
        if (call.PDFTransactionStatus === "") {
            call.showPdfStatus = false;
            call.showPdfLog = false;
        } else {

            call.PdfStatus = pdfTransactionStatusHelper.getPdfStatus(call.PDFTransactionStatus);
            call.showPdfStatus = call.PdfStatus && (call.PdfStatus.sendStatus !== this.enumPdfStatus.NOTPROCESSED || call.PdfStatus.saveStatus !== this.enumPdfStatus.NOTPROCESSED);
            call.showPdfLog = call.PdfStatus && (call.PdfStatus.sendStatus === this.enumPdfStatus.ERROR || call.PdfStatus.saveStatus === this.enumPdfStatus.ERROR);
            if (call.PdfStatus) {
                switch (call.PdfStatus.sendStatus) {
                case this.enumPdfStatus.NOTPROCESSED:
                    call.PdfStatus.sendClass = "fa fa-fw";
                    break;
                case this.enumPdfStatus.PENDING:
                    call.PdfStatus.sendClass = "yellow ma ma-send";
                    break;
                case this.enumPdfStatus.SUCCESS:
                    call.PdfStatus.sendClass = "green ma ma-send";
                    break;
                case this.enumPdfStatus.ERROR:
                    call.PdfStatus.sendClass = "red ma ma-send-error";
                    break;
                default:
                    break;
                }

                switch (call.PdfStatus.saveStatus) {
                case this.enumPdfStatus.NOTPROCESSED:
                    call.PdfStatus.saveClass = "fa fa-fw";
                    break;
                case this.enumPdfStatus.PENDING:
                    call.PdfStatus.saveClass = "yellow ma ma-saveToDisk";
                    break;
                case this.enumPdfStatus.SUCCESS:
                    call.PdfStatus.saveClass = "green ma ma-saveToDisk";
                    break;
                case this.enumPdfStatus.ERROR:
                    call.PdfStatus.saveClass = "red ma ma-saveToDisk-error";
                    break;
                default:
                    break;
                }
            }
        }
    }

    public initializeUnplannedCallsList(data: any): any[] {
        const unls = [];

        const tempUnls = _.filter(data.Calls, (call: any) => !call.AssignedDate);
        if (tempUnls.length > 0) {
            unls.push({
                date: "",
                helperName: "",
                assignedEquipmentDescription: "",
                ls: tempUnls,
                isOpened: true
            });
        }

        return unls;
    }

    public pushCallsByDate(ls: any[], data: any): void {
        _.each(ls, (item: any) => {
            item.ls = _.filter(data.Calls, (call: any) => {
                return dateHelper.dateFromUTC(call.AssignedDate, "ddd, ll") === item.date;
            });
        });
    }

    public disposeAll(): void {
        this.currentList = [];
        this.unplannedList = [];
        this.historyList = [];
        this.list = [];
        this.disposeScroll();
    }

    public initScroll(): void {
        jQuery(document).scroll(this.scrollHandler.bind(this));
        this.showScrollMessage("scroll");
    }

    public disposeScroll(): void {
        jQuery(document).off("scroll");
        this.hideScrollMessage();
    }

    public scrollHandler(): any {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessage("loading");
            this.page += 1;
            this.getPreviousData(this.page)
                .then((ls: any) => {
                    let actualLs = this.list;
                    ls = this.getPreviousDataDone(ls);
                    actualLs = actualLs.concat(ls);
                    this.list = actualLs;
                }, () => {
                    this.list = [];
                });

        }
    }

    public showScrollMessage(msgId: string): void {
        if (msgId === "loading") {
            this.scrollMessageText = this.i18n.tr("LoadingMoreResults");
        } else {
            this.scrollMessageText = this.i18n.tr("ScrollForMore");
        }

        this.isScrollMessageShown = true;
    }

    public hideScrollMessage(): void {
        this.isScrollMessageShown = false;
    }

    public getUnitSystem(): string {
        const unitSystem = settings.getUnitSystem();
        return unitSystem ? unitSystem : UnitSystem.Metric;
    }

    public async selectedWithinRangeValueChanged(): Promise<void> {
        if (this.sectionShown === this.sectionTabs.UNPLANNED) {
            await UserSettingService.setUnPlannedServiceCallWithinRangeSetting(this.selectedWithinRange.id, this.getUnitSystem());
            this.loadUnplannedData();
        }
    }

    public async selectedEmergencyTypeValueChanged(): Promise<void> {
        if (this.sectionShown === this.sectionTabs.UNPLANNED) {
            this.loadUnplannedData();
        }
    }

    public async loadWithinRanges(): Promise<void> {
        this.withinRanges = this.getWithinRanges();

        const usersetting = await UserSettingService.getUnPlannedServiceCallWithinRangeSetting(this.getUnitSystem());
        if (usersetting) {
            this.userSelectedWithinRangeId = usersetting.Value;
        }

        this.selectedWithinRange = this.withinRanges.find((unitSystem: any) => unitSystem.id === this.userSelectedWithinRangeId.toString());
    }

    public async loadEmergencyTypes(): Promise<any> {
        this.selectedEmergencyType = this.emergencyTypeAll;
        const selectedEmergencyTypeInit = [this.emergencyTypeAll];
        const emergencyTypestbl = _.map(_.filter(await this.serviceCallEmergencyTypeService.getEmergencyTypes(), (etype: any) => etype.Description !== "" ), (emergencyType: ServiceCallEmergencyTypeModel) => {
            return { id: emergencyType.Id, text: emergencyType.Id + " - " + emergencyType.Description };
        });

        this.emergencyTypes = selectedEmergencyTypeInit.concat(emergencyTypestbl);
    }

    public getWithinRanges(): any[] {
        this.UnitSystemToDisplay = this.getUnitSystem();

        if (this.UnitSystemToDisplay !== UnitSystem.Imperial) {
            return enumHelper.serviceCallMetricDtistances();
        }

        return enumHelper.serviceCallImperialDtistances();
    }

    public show(param: any): void {
        this.sectionShown = param;
        switch (param) {
        case this.SECTION_TABS.UNPLANNED:
            //validate if template has been selected for entry adding button
            if (this.addEntry !== enumHelper.serviceCallAddEntryStatus().MISSINGTEMPLATE.toString()) {
                this.loadUnplannedData();
            } else {
                settingHelper.getSelectedDispatchModel();
            }
            this.disposeScroll();
            break;
        case this.SECTION_TABS.COMPLETED:
            if (this.historyList.length === 0) {
                this.loadPreviousData();
            } else {
                this.list = this.historyList;
                if (!this.completedFinishedLoading) {
                    this.initScroll();
                }
            }
            break;
            default:
                if (this.currentList.length === 0) {
                    this.loadData(true);
                }
                this.list = this.currentList;
                this.disposeScroll();
        }
        defaultService.setServiceLastSectionShown(param);
    }

    public showPdfLog(call: any): void {
        if (call.showPdfLog) {
            notificationHelper.showDialogOk(call.PdfStatus.message, call.PdfStatus.title);
        }
    }

    public getPresence(presenceValue: any): any {
        return _.first(_.where(this.servicePresenceTypes, { id: presenceValue }));
    }

    public toggleOpenSection(section: any): void {
        section.isOpened = !section.isOpened;
    }

    public goToAddEntry(): void {
        if (settingHelper.getSelectedDispatchModel() !== "") {
           routerHelper.navigateToRoute(this.routeRepository.routes.Service_Add.name);
        }
    }

    public goToAddWO(): void {
        if (settingHelper.getSelectedDispatchModel() !== "") {
            routerHelper.navigateToRoute(this.routeRepository.routes.Service_Add_Wo.name);
        }
    }

    public navigateToQuotations(): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotations_Mobile.name);
    }

    public navigateToQuotation(quotationId: number): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Service_Quotation_Mobile.name, { quotationId: quotationId });
    }

    public openAddress(assignment: any): void {
        window.open(routerHelper.mapAddress(assignment.Location.Address + ", " + assignment.Location.City + " (" + assignment.Location.Province + ") " + assignment.Location.PostalCode));
    }

    public getMaintenanceTypeDescription(maintenanceType: string): string {
        if (maintenanceType) {
            return this.i18n.tr("WorkOrderMaintenanceType_" + maintenanceType);
        }
        return "";
    }

}
