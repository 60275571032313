import { singleton } from "aurelia-framework";

import { default as contactService } from "services/contactService";
import { default as recommendationService } from "services/recommendationService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

export class RecommendationEdit {
    public readonly: boolean = false;
    public workOrderId: string = "";
    public recommendationId: any = "";
    public fetchClients: any;
    public fetchDefaultContact: any;
    public saveRecommendation: any;

    public activate(params: any): any {
        this.bindViewModel(params.workOrderId, params.recommendationId, params.q);
    }

    public bindViewModel(workOrderId: string, recommendationId: string, querystring: string): void {
        const params = routerHelper.getQuerystring(querystring);
        this.recommendationId = recommendationId;
        this.workOrderId = workOrderId;
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }

        this.fetchDefaultContact = contactService.getContactLookupForWorkOrder.bind(contactService, workOrderId);
        this.saveRecommendation = recommendationService.addRecommendationForWorkOrder.bind(recommendationService, workOrderId);
    }
}
