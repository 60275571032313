import { ServiceCallQuotationItemType } from "api/enums/service-call-quotation-item-type";
import { ServiceCallQuotationSortOrder, nameof as nameof_ServiceCallQuotationSortOrder } from "api/enums/service-call-quotation-sort-order";
import { ServiceCallQuotationStatus, nameof as nameof_ServiceCallQuotationStatus } from "api/enums/service-call-quotation-status";
import { ServiceCallQuotationDetailsModel } from "api/models/company/service-call/service-call-quotation-details-model";
import { autoinject, computedFrom, observable } from "aurelia-framework";
import { Tab } from "components/layout/tab";
import { EnumFormatValueConverter } from "converters/enums/enum-format";
import { EnumHelper } from "helpers/enum-helper";
import { QuotationListBase, QuotationListParameters } from "pages/services/quotations/list-base";
import RouteRepository, { NavigationNew } from "repositories/routeRepository";
import { ServiceCallQuotationSecurityService } from "services/service-call-quotation-security-service";
import { ServiceCallQuotationService } from "services/service-call-quotation-service";
import UserAccessService from "services/user-access-service";
import { ViewModeHelper } from "helpers/view-mode-helper";

interface TabInfo {
    id: string;
    titleKey: string;
    selected: boolean;
}

enum QuotationListTab {
    InProgress = "InProgress",
    Others = "Others"
}

@autoinject()
export class QuotationListLight extends QuotationListBase {
    @observable
    public searchValue: string = "";

    @observable
    public selectedSortOrder: ServiceCallQuotationSortOrder = ServiceCallQuotationSortOrder.Date;

    public type: any;

    public readonly NavigationNew: string = NavigationNew;
    public readonly nameof_ServiceCallQuotationSortOrder: string = nameof_ServiceCallQuotationSortOrder;
    public readonly nameof_ServiceCallQuotationStatus: string = nameof_ServiceCallQuotationStatus;
    public readonly sortOrders: ServiceCallQuotationSortOrder[] = EnumHelper.getNumericValues(ServiceCallQuotationSortOrder);

    public quotationsSortedAndFiltered: ServiceCallQuotationDetailsModel[] = [];
    public currentTabId: string = QuotationListTab.InProgress;

    public readonly tabs: TabInfo[] = [
        { id: QuotationListTab.InProgress, titleKey: "InProgress", selected: true },
        { id: QuotationListTab.Others, titleKey: "Others", selected: false }
    ];

    @computedFrom("securitySettings")
    public get canCreateQuotation(): boolean {
        return this.serviceCallQuotationSecurityService.canCreateQuotationInMobile(this.securitySettings);
    }

    constructor(
        serviceCallQuotationService: ServiceCallQuotationService,
        serviceCallQuotationSecurityService: ServiceCallQuotationSecurityService,
        userAccessService: UserAccessService,
        viewModeHelper: ViewModeHelper,
        public readonly enumFormatValueConverter: EnumFormatValueConverter,
        private readonly routeRepository: RouteRepository
    ) {
        super(serviceCallQuotationService, serviceCallQuotationSecurityService, userAccessService, viewModeHelper);
    }

    public async activate(params: QuotationListParameters): Promise<void> {
        await super.activate(params);
        await this.initQuotations();
    }

    public searchValueChanged(newValue: string): void {
        if (!this.quotations) { return; }

        this.quotationsSortedAndFiltered = this.sortAndFilterQuotations(this.selectedSortOrder, this.searchValue);
    }

    public selectedSortOrderChanged(newValue: ServiceCallQuotationSortOrder): void {
        if (!this.quotations) { return; }

        this.quotationsSortedAndFiltered = this.sortAndFilterQuotations(this.selectedSortOrder, this.searchValue);
    }

    public async selectedTabChanged(event: CustomEvent<Tab>): Promise<void> {
        if (event.detail.tabId === this.currentTabId) { return; }

        this.currentTabId = event.detail.tabId;
        await this.initQuotations();
    }

    private sortAndFilterQuotations(sortOrder: ServiceCallQuotationSortOrder, searchValue: string): ServiceCallQuotationDetailsModel[] {
        let tempQuotations = this.serviceCallQuotationService.filterQuotations(this.quotations, searchValue);
        tempQuotations = this.serviceCallQuotationService.sortQuotations(tempQuotations, sortOrder);
        return tempQuotations;
    }

    private async initQuotations(): Promise<void> {
        this.quotations = await this.fetchQuotations();
        this.quotationsSortedAndFiltered = this.sortAndFilterQuotations(this.selectedSortOrder, this.searchValue);
    }

    private async fetchQuotations(): Promise<ServiceCallQuotationDetailsModel[]> {
        const statuses: string[] | null = this.currentTabId === QuotationListTab.InProgress ? [ServiceCallQuotationStatus.InProgressByTheTechnician, ServiceCallQuotationStatus.OnHold] : null;
        const excludedStatuses: string[] | null = this.currentTabId === QuotationListTab.InProgress ? null : [ServiceCallQuotationStatus.InProgressByTheTechnician, ServiceCallQuotationStatus.OnHold];

        return await this.serviceCallQuotationService.getQuotations(null, this.sourceServiceCallId, statuses, excludedStatuses, false, true, this.sourceWorkOrderId);
    }
}
