import { default as recommendationService } from "services/recommendationService";
import { default as routerHelper } from "helpers/routerHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";

export class Recommendation {
    public workOrderId: string = "";
    public fetchRecommendations: any;
    public readonly: boolean = false;

    public activate(params: any): any {
        this.bindViewModel(params.workOrderId, params.q);
    }

    public bindViewModel(workOrderId: string, querystring: string): any {
        const params = routerHelper.getQuerystring(querystring);
        if (params.readonly) {
            this.readonly = queryStringHelper.parseReadonly(querystring);
        }
        this.workOrderId = workOrderId;
        this.fetchRecommendations = recommendationService.getRecommendationsForWorkOrder.bind(this, workOrderId);
    }
}
