import { GeolocationService } from "services/geolocationService";

define([
    "jquery",
    "underscore",
    "services/requests",
    "helpers/apiHelper"
], function (jQuery, _, requests, apiHelper) {
    "use strict";

    var geolocationService = new GeolocationService();

    var exports = {
        breakEquipmentLink: function (dispatchId) {
            return requests.post(apiHelper.getProjectDispatchBaseUrl(dispatchId) + "/breaklink");
        },

        getPositionToLog: function () {
            var dfd = new jQuery.Deferred();

            geolocationService.getCurrentLocation().done(function (position) {
                var dataToPost = {
                    Latitude: position.coords.latitude,
                    Longitude: position.coords.longitude
                };
                dfd.resolve(dataToPost);
            }).fail(function () { dfd.resolve({}); });

            return dfd;
        },

        delProjectDispatch: function (dispatchId) {
            return requests.remove(apiHelper.getProjectDispatchBaseUrl(dispatchId));
        },

        dipatchProjectHasTimeEntriesAndBonus: function (dispatchId) {
            return requests.getJson(apiHelper.getProjectDispatchBaseUrl(dispatchId));
        },

        dipatchProjectisLastAttendee: function (dispatchId) {
            return requests.getJson(apiHelper.getProjectDispatchBaseUrl(dispatchId) + "/is-last-attendee");
        },

        setAttendanceStatus: function (dispatchId, data) {
            return requests.post(apiHelper.getProjectDispatchBaseUrl(dispatchId) + "/attendance/", data);
        },

        getClientLookupForDispatch: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/client/lookup");
        },

        getClient: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/client");
        },

        setComment: function (dispatchId, data) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/comment", data);
        },

        getComment: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/comment");
        },

        getHeader: function (dispatchId) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/header");
        },

        printform: function (dispatchId, data) {
            return requests.postAndForget(apiHelper.getDispatchBaseUrl(dispatchId) + "/printform", data);
        },

        setServiceCallAccept: function (dispatchId, startDate) {
            var dfd = new jQuery.Deferred();

            this.getPositionToLog().done(function (localizationModel) {
                var url = apiHelper.getDispatchBaseUrl(dispatchId) + "/accept";
                if (startDate) {
                    url += "?startDate=" + startDate;
                }
                requests.post(url, localizationModel).done(dfd.resolve).fail(dfd.reject);
            });
            return dfd;
        },

        setServiceCallRefuse: function (dispatchId, reason) {
            var dfd = new jQuery.Deferred();
            this.getPositionToLog().done(function (localizationModel) {
                var data = {
                    Reason: reason,
                    LocalizationModel: localizationModel
                };
                requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/refuse", data).done(dfd.resolve).fail(dfd.reject);
            });
            return dfd;
        },

        setServiceCallEnRoute: function (dispatchId, startDate, endDate) {
            var dfd = new jQuery.Deferred();
            this.getPositionToLog().done(function (localizationModel) {
                requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/enroute?transportStartTime=" + startDate + "&transportEndTime=" + endDate, localizationModel).done(dfd.resolve).fail(dfd.reject);
            });
            return dfd;
        },

        serviceCallEnRouteNotify: function (dispatchId) {
            var dfd = new jQuery.Deferred();
            requests.postNoLoading(apiHelper.getDispatchBaseUrl(dispatchId) + "/enroute-notify").done(dfd.resolve).fail(dfd.reject);
            return dfd;
        },

        serviceCallAcceptNotify: function (dispatchId) {
            var dfd = new jQuery.Deferred();
            requests.postNoLoading(apiHelper.getDispatchBaseUrl(dispatchId) + "/accept-notify").done(dfd.resolve).fail(dfd.reject);
            return dfd;
        },

        setServiceCallOnSite: function (dispatchId, startDate, endDate) {
            var dfd = new jQuery.Deferred();
            this.getPositionToLog().done(function (localizationModel) {
                requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/onsite?startTime=" + startDate + "&endDateTime=" + endDate, localizationModel).done(dfd.resolve).fail(dfd.reject);
            });
            return dfd;
        },

        setServiceCallFinish: function (dispatchId, endDate, data) {
            var dfd = new jQuery.Deferred();
            this.getPositionToLog().done(function (localizationModel) {
                data.localizationModel = localizationModel;
                requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/finish?endDateTime=" + encodeURIComponent(endDate), data).done(dfd.resolve).fail(dfd.reject);
            });
            return dfd;
        },

        // TODO: KG - ShowLoading: params
        getListByDispatchProjectCode: function (dispatchProjectCode, statuses, statusestoExclude, sortByAsc, includeReturnedEntries, page, maxDate) {
            var url = apiHelper.getDispatchProjectBaseUrl(dispatchProjectCode) + "/dispatchDates";
            var params = { 'includeReturnedEntries': includeReturnedEntries, 'page': page, 'sortByAsc': sortByAsc };

            if (maxDate) {
                _.extend(params, { 'maxDate': maxDate });
            }

            var arrayStr;

            if (statuses) {
                arrayStr = statuses.join("&statuses=");
                _.extend(params, { 'statuses': arrayStr });
            }

            if (statusestoExclude) {
                arrayStr = statusestoExclude.join("&statusestoExclude=");
                _.extend(params, { 'statusestoExclude': arrayStr });
            }

            url = apiHelper.appendQueryStringParameters(url, params);

            return requests.getJson(url);
        },

        setHasLeft: function (dispatchId, val) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/hasLeft/" + val);
        },

        setInactive: function (dispatchId, val) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/inactive/" + val);
        },

        setHasLeftDispatches: function (val, data) {
            return requests.post(apiHelper.getBaseUrl() + "/dispatches/hasleft/" + val, data);
        },

        setRequiredTomorrow: function (dispatchId, val) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/requiredTomorrow/" + val);
        },

        getHoursSummary: function (dispatchDate, maxDispatchDate) {
            return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(null, dispatchDate) + "/" + maxDispatchDate  + "/timeentriessummary");
        },

        setServiceCallEnRouteStop: function (dispatchId) {
            var dfd = new jQuery.Deferred();
            this.getPositionToLog().done(function (localizationModel) {
                requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/enroutestop", localizationModel).done(dfd.resolve).fail(dfd.reject);
            });
            return dfd;
        },

        getEnRouteCheck: function (dispatchId) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/enroutecheck");
        },

        // TODO: KG - ShowLoading: false or true - Transport
        getGroupedEmployees: function (dispatchId, excludeTeamLeader, onlyNotCompleted) {
            return requests.getJson(apiHelper.getDispatchBaseUrl(dispatchId) + "/groupedEmployees?excludeteamleader=" + excludeTeamLeader + "&onlynotcompleted=" + onlyNotCompleted);
        },

        setTeamLeader: function (dispatchId, employeeId) {
            return requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/setteamleader/" + employeeId);
        },

        delWorkorderDispatch: function (workorderId, dispatchId) {
            return requests.remove(apiHelper.getFieldServiceWorkOrderAttendancesBaseUrl(workorderId, dispatchId));
        },

        setWorkorderAttendanceStatus: function (workorderId, dispatchId, data) {
            return requests.post(apiHelper.getFieldServiceWorkOrderAttendancesBaseUrl(workorderId, dispatchId) + "/attendance", data);
        },

        setWorkorderInactive: function (workorderId, dispatchId, val) {
            return requests.post(apiHelper.getFieldServiceWorkOrderAttendancesBaseUrl(workorderId, dispatchId) + "/inactive/" + val);
        },

        sign: function (dispatchId, data) {
            var dfd = new jQuery.Deferred();
            requests.post(apiHelper.getDispatchBaseUrl(dispatchId) + "/Sign", data).done(dfd.resolve).fail(dfd.reject);
            return dfd;
        },
    };

    return exports;
});
