import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { autoinject } from "aurelia-framework";
import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import routerHelper from "helpers/routerHelper";
import { I18N } from "aurelia-i18n";
import { NotificationHelper } from "helpers/notification-helper";
import { Redirect, Router } from "aurelia-router";

@autoinject()
export class ServiceMaterialRequisitionEdit {

    public actions: any = {};
    public dispatchId: number = 0;
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;
    public readonly: boolean = false;
    public isDirty: boolean = false;
    public parentModel: any = null;

    constructor(private readonly i18n: I18N, private readonly notificationHelper: NotificationHelper, private readonly router: Router) {
    }

    public activate(params: any): any {
        this.dispatchId = params.dispatchId;
        this.requisitionId = params.requisitionId;
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.parentModel = this;
    }

    public async canDeactivate(): Promise<any> {
        if (this.isDirty) {
            routerHelper.hideLoading(true);
            const msgWarning = this.i18n.tr("msg_UnsavedChangedWillBeLostConfirmation");
            const confirm = await this.notificationHelper.showDialogYesNo(msgWarning);
            if (!confirm) {
                return new Redirect((this.router.history as any).previousLocation, { trigger: false, replace: false });
            }
        }
        return true;
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForService.bind(materialRequisitionService, this.dispatchId),
            setSpecificFields: this.setSpecificFields.bind(this)
        };

    }

    public setSpecificFields(item: MaterialRequisitionModel): any {
        item.DispatchId = this.dispatchId;
        return item;
    }

}
