import urlHelper from "helpers/urlHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { autoinject, bindable } from "aurelia-framework";

import routerHelper from "helpers/routerHelper";
import RouteRepository from "repositories/routeRepository";

import { CompanyService } from "services/company-service";
import { InventorySiteTransferService } from "services/inventory-site-transfer-service";

import { InventorySiteTransferDetailsModel } from "api/models/company/inventory-site-transfer-details-model";
import { CompanyModel } from "api/models/common/company/company-model";
import val from "core/val";

@autoinject()
export class InventorySiteTransferEdit {
    @bindable public option: any = "";
    @bindable public transfer!: InventorySiteTransferDetailsModel | null;

    @bindable public isMultiPrefix: boolean = false;

    @bindable public isNewTransfer: boolean = true;
    public val: any = val;

    constructor(
        private routeRepository: RouteRepository,
        private readonly inventorySiteTransferService: InventorySiteTransferService,
        private readonly companyService: CompanyService    ) {}

    public async activate(params: any): Promise<void> {
        this.isNewTransfer = !params.transferId;

        if (this.isNewTransfer) {
            this.transfer = await this.inventorySiteTransferService.NewTransfer();
        } else {
            this.transfer = await this.inventorySiteTransferService.GetTransfer(params.transferId);
        }

        this.isMultiPrefix = settingHelper.isMultiPrefix();
    }

    public async getSourceCompanies(): Promise<CompanyModel[] | null> {
        return await this.companyService.getAll();
    }

    public async save(): Promise<void> {
        await this.inventorySiteTransferService.SaveTransfer(this.transfer!).then((result: number | null): any => {
            this.replaceBrowsingHistory();
            routerHelper.navigateToRoute(this.routeRepository.routes.InventorySiteTransfer_Info.name, { transferId: result });
        });
    }

    private replaceBrowsingHistory(): void {
        const baseUrl = urlHelper.getBaseUrlWithDash();
        const route = this.routeRepository.routes.InventorySiteTransfer.route.toString();
        window.history.replaceState("", "", baseUrl + route);
    }
}
