import { inject, observable, bindable, computedFrom } from "aurelia-framework";
import { ValidationRules, ValidationControllerFactory, validateTrigger } from "aurelia-validation";

import { I18N } from "aurelia-i18n";

import { default as _ } from "underscore";
import { default as val } from "core/val";

import { default as dateHelper } from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as notifier } from "helpers/notificationHelper";

import { default as materialRequisitionService } from "services/materialRequisitionService";

const defaultScaleFactor = 7;
@inject(ValidationControllerFactory, I18N)
export class MaterialRequisitionItemMultiCustomElement {

    dateRequired = null;
    description1 = "";
    description2 = "";
    description3 = "";
    quantity = 1;
    unitDescription = null;
    showCodeInput = false;
    outCatalogCode = "";
    activities = null;
    selectedActivity = null;
    unModifiedMaterial = null;

    materialRequisitionItemCards = [];
    cardIndex = 0;

    @bindable requisitionId;
    @bindable catalogBaseUrl;
    @bindable lineNo = 0;
    @bindable requisitionDate;
    @bindable showNotInCatalog = false;
    @bindable actions;
    @bindable readonly = false;
    @bindable showActivities = false;
    @bindable isDirty = false;

    @observable selectedItem;
    @observable inventoryScaleFactor = defaultScaleFactor;

    @computedFrom("selectedItem", "quantity", "description1", "selectedActivity", "outCatalogCode", "materialRequisitionItemCards.length", "unModifiedMaterial")
    get isDirtyCheck() {
        if (this.readonly) {
            this.isDirty = false;
            return this.isDirty;
        }

        if (!this.unModifiedMaterial) {
            this.isDirty = false;
            return this.isDirty;
        }

        if (this.materialRequisitionItemCards.length > 0) {
            this.isDirty = true;
            return this.isDirty;
        }

        const stringifyUnmodifiedMaterial = JSON.stringify(this.unModifiedMaterial).replace(/[^0-9A-Z]+/gi, "");

        const current = this.getMaterialObject();
        const stringifyCurrentMaterial = JSON.stringify(current).replace(/[^0-9A-Z]+/gi, "");

        this.isDirty = stringifyUnmodifiedMaterial !== stringifyCurrentMaterial;

        return this.isDirty;
    }

    @computedFrom("readonly", "isNew", "showNotInCatalog")
    get catalogPickerDisabled() {
        return this.readonly || (!this.isNew && this.showNotInCatalog);
    }

    @computedFrom("lineNo")
    get isNew() {
        if (!this.lineNo) { return true; }
        return false;
    }

    constructor(validationControllerFactory, i18n) {
        this.descriptionMaxLength = val.get('materialBillingEdit.description', 'maxLength');
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.validateTrigger = validateTrigger.manual;

        this.i18n = i18n;
        //Validationrules
        ValidationRules
            .ensure(x => x.quantity).required().withMessageKey("err_QuantityRequired")
            .ensure(x => x.selectedItem).required().withMessageKey("err_ItemRequired")
            .ensure(x => x.description1).required().withMessageKey("err_DescriptionRequired")
            .on(this);
    }

    selectedItemChanged(newValue, oldValue) {
        if (this.selectedItem === undefined) {
            this.description1 = "";
            this.description2 = "";
            this.description3 = "";
            this.unitDescription = null;

            this.inventoryScaleFactor = defaultScaleFactor;
        } else if (this.selectedItem.id === "!") {
            this.description1 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description1;
            this.description2 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description2;
            this.description3 = this.selectedItem.data ? (this.selectedItem.data.scannedValue ? this.selectedItem.data.scannedValue : "") : this.selectedItem.Description3;
            this.unitDescription = null;
            this.showCodeInput = true;

            this.inventoryScaleFactor = defaultScaleFactor;
        } else {
            this.description1 = this.selectedItem.data ? this.selectedItem.data.Description1 : this.selectedItem.Description1;
            this.description2 = this.selectedItem.data ? this.selectedItem.data.Description2 : this.selectedItem.Description2;
            this.description3 = this.selectedItem.data ? this.selectedItem.data.Description3 : this.selectedItem.Description3;
            this.unitDescription = (newValue.data ? newValue.data.UnitDescription : "");

            this.inventoryScaleFactor = this.selectedItem.data ? this.selectedItem.data.InventoryScaleFactor : defaultScaleFactor;
        }

        if (newValue && newValue.data) {
            if (this.showActivities) {
                this.selectedActivity = this.createDefaultMaSelectElement(newValue.data.Activity, this.activities);
            }
        }
    }

    formatDescription(desc1, desc2, desc3) {
        var descArray = [desc1, desc2, desc3];
        descArray = _.filter(descArray, function(n){
            return n !== "";
        });

        return descArray.join(" | ");
    }

    clearForm() {
        this.dateRequired = this.requisitionDate;
        this.description1 = "";
        this.description2 = "";
        this.description3 = "";
        this.quantity = 1;
        this.selectedItem = undefined;
        this.unModifiedMaterial = this.getMaterialObject();
    }

    async bind() {
        if (!this.lineNo) { this.lineNo = 0; }
        return await this.loadData();
    }

    getMaterialObject() {
        var item = {
            Code: this.selectedItem ? this.selectedItem.id : 0,
            Description: this.formatDescription(this.description1, this.description2, this.description3),
            Line: this.lineNo,
            Quantity: this.quantity,
            RequiredDate: dateHelper.formatDateToSend(this.dateRequired),
            Id: this.requisitionId,
            OutCatalogCode: this.outCatalogCode,
            Activity: this.selectedActivity ? this.selectedActivity.id : null
        }

        item = this.actions.setSpecificFields(item);

        return item;
    }

    async saveItem() {
        if(this.materialRequisitionItemCards.length > 0)
        {
            var dataList = this.materialRequisitionItemCards.map(card => card.model);

            this.actions.saveMaterialRequisitionItem(this.requisitionId, this.lineNo, dataList)
                .done(() => {
                    this.materialBillingItemCards = [];
                    this.unModifiedMaterial = null;
                routerHelper.navigateBack();
            }).
            always(() => {
                routerHelper.hideLoading();
            });

            return;
        }

        const result = await this.validationController.validate();

        if (result.valid) {
            routerHelper.showLoading();
            var item = this.getMaterialObject();

            item = this.actions.setSpecificFields(item);

            this.actions.saveMaterialRequisitionItem(this.requisitionId, this.lineNo, [item])
                .done(() => {
                    this.unModifiedMaterial = null;
                    routerHelper.navigateBack();
                }).
            always(() => {
                routerHelper.hideLoading();
            });
        } else {
            const errors = _.chain(result.results).filter((result) => { return !result.valid }).pluck("message").value();
            notifier.showValidationError(errors);
        }
    }

    async loadData() {
        const materialRequisition = await materialRequisitionService.getMaterialRequisition(this.requisitionId).promise();

        this.readonly |= materialRequisition.MobileStatus === "1";

        if (this.showActivities) {
            const actList = await this.actions.getActivities();
            this.activities = _.filter(actList, function (act) { return act.AvailableInMobileForMaterial }).map(act => { return { id: act.Id, text: act.Id + " - " + act.Description } });
        }

        if (this.isNew) {
            this.clearForm();
            return;
        }

        const materialRequisitionItem = await materialRequisitionService.getMaterialRequisitionItem(this.requisitionId, this.lineNo).promise();

        this.selectedItem = { id: materialRequisitionItem.Code, text: materialRequisitionItem.Code === "!" ? this.i18n.tr('AddItemNotInCatalog') : materialRequisitionItem.Code };
        this.description1 = materialRequisitionItem.Description1;
        this.description2 = materialRequisitionItem.Description2;
        this.description3 = materialRequisitionItem.Description3;
        this.dateRequired = dateHelper.parseDate(materialRequisitionItem.RequiredDate);
        this.quantity = materialRequisitionItem.Quantity;
        this.unitDescription = materialRequisitionItem.UnitDescription;
        this.showNotInCatalog = (materialRequisitionItem.Code === "!");
        this.showCodeInput = (materialRequisitionItem.Code === "!")
        this.outCatalogCode = materialRequisitionItem.OutCatalogCode;

        this.inventoryScaleFactor = materialRequisitionItem.InventoryScaleFactor;

        if (this.showActivities) {
            this.selectedActivity = this.createDefaultMaSelectElement(materialRequisitionItem.Activity, this.activities);
        }

        this.unModifiedMaterial = this.getMaterialObject();
    }

    createDefaultMaSelectElement(id, list) {
        if (!id) { return null; }

        return list.find((item) => item.id === id);
    }

    async add() {
        const result = await this.validationController.validate();

        if (result.valid) {
            var item = {
                Code: this.selectedItem.id,
                Description: this.formatDescription(this.description1, this.description2, this.description3),
                Line: this.lineNo,
                Quantity: this.quantity,
                RequiredDate: dateHelper.formatDateToSend(this.dateRequired),
                Id: this.requisitionId,
                OutCatalogCode: this.outCatalogCode,
                Activity: this.selectedActivity ? this.selectedActivity.id : null
            };

            item = this.actions.setSpecificFields(item);

            this.materialRequisitionItemCards.push(this.createCard(item));
            this.clearForm();
        } else {
            const errors = _.chain(result.results).filter((result) => { return !result.valid }).pluck("message").value();
            notifier.showValidationError(errors);
        }

    }

    delete(cardIndex) {
        this.materialRequisitionItemCards = this.materialRequisitionItemCards.filter((card) => {
            return card.id !== cardIndex;
        });
    }

    createCard(item) {
        this.cardIndex++;

        const card = {
            id: this.cardIndex,
            model: item,
            displayLines: [
                { display: `${item.Code === "!" ? item.Code + item.OutCatalogCode:item.Code}`, class: "text-primary font-weight-bold"},
                { display: `${this.i18n.tr("DateRequired")}: ${dateHelper.formatDate("YYYY-MM-DD", item.RequiredDate)}`, class: "font-weight-bold" },
                { display: `${this.i18n.tr("Description")}: ${item.Description}`, class: "font-weight-bold" },
                { display: `${this.i18n.tr("Quantity")}: ${item.Quantity}`, class: "font-weight-bold" },

            ],
            actionItems: [{id: "delete", icon: "fa fa-trash text-danger", action: this.delete.bind(this, this.cardIndex) }],
        };

        return card;
    }
}
